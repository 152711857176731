<template>
  <div id="projectContainerDiv" class="proj-container">
    <Modalprojects ref="modalFusionProjects" show-modal-sync="showmodal" />
    <ModalExport ref="modalExport" show-modal-sync="showmodal" />
    <div
      :class="[
        'h-full grid gap-2 m-2 xl:grid-cols-proj-comp',
        hideProjectComponentFlag == true ? 'md:grid-cols-1 lg:grid-cols-1' : 'md:grid-cols-2 lg:grid-cols-2'
      ]"
    >
      <div
        :class="[
          hideProjectComponentFlag == true ? 'opacity-0 h-0 w-0 absolute hidden' : 'opacity-100 w-full',
          'proj-comp-wrap'
        ]"
      >
        <ProjectComponent
          :nom-projet="project.nom"
          :id-project="project.id"
          :project-data="project"
          :etat-traitement="project.etatTraitement"
          :etat-reception-photos="project.etatReceptionPhotos"
          :etat-pre-traitement="project.etatPreTraitement"
        />
      </div>
      <div
        class="w-full h-full border-solid border-2 rounded-md border-black-1"
        :class="[hideProjectComponentFlag == true ? 'xl:col-span-3' : 'xl:col-span-2']"
      >
        <PotreeContainer ref="potreecontainer" :type-potree="0" :scene2="project" />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectComponent from '@/components/ProjectComponent.vue';
import PotreeContainer from '@/components/PotreeContainer.vue';
import gql from 'graphql-tag';
import Modalprojects from './ModalProjectsFusion.vue';
import ModalExport from './ModalExport.vue';

export default {
  name: 'ProjectResponsiveContainer',
  components: { ProjectComponent, PotreeContainer, Modalprojects, ModalExport },
  props: {
    nomProjet: { type: String, default: '' },
    project: { type: Object, default: null }
    // sceneFusionList: Array,
  },
  data() {
    return {
      showMenu: true,
      showmodal: false,
      hideProjectComponentFlag: false,
      sceneListModal: []
    };
  },
  methods: {
    fusion_project() {
      this.showmodal = true;
      this.$refs.modalFusionProjects.toggleModal(this.project.id);
    },
    hideProjectComponent(isVisible) {
      this.hideProjectComponentFlag = isVisible;
    }
  },

  apollo: {
    $client: 'apolloProjectClient',
    $subscribe: {
      onUpdateProject: {
        query: gql`
          subscription {
            onUpdateProject {
              projet {
                id
                geom
                nom
                statusProjet
                etatTraitement
                thubnail
              }
            }
          }
        `,
        result({ data }) {
          for (let scen in this.sceneList) {
            if (scen.id == data.onUpdateScene.scene) {
              scen = data.onUpdateScene.scene;
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.proj-container {
  display: block;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .proj-container {
    flex: 1;
    padding-right: 0;
    padding-left: 0;
  }
  .proj-comp-wrap {
    display: flex;
    overflow: hidden;
  }
}
</style>
